// @TODO: GalleryShare - maybe the component will be removed, add item title, description boolean

import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { useViewportWidth } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { SocialShare } from '../../../Social/components'
import styles from './Meta.style'

const GalleryMetaStyled = styled.div(props => ({ ...styles(props) }))

function GalleryMeta({ author, photoAuthor, date, shareUrl, title, className, ...rest }) {
    const galleryDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])
    const isAmp = useAmp()
    const viewportWidth = useViewportWidth()

    return (
        <GalleryMetaStyled className={className} viewportWidth={viewportWidth} {...rest}>
            {(author || date) && (
                <div className="galleryMeta_inner">
                    {author && (
                        <div className="galleryMeta_author">
                            {author}
                            {photoAuthor && (
                                <>
                                    <span className="galleryMeta_photoAuthor"> • Foto </span>
                                    <span> {photoAuthor}</span>
                                </>
                            )}
                        </div>
                    )}
                    {date && <div className="galleryMeta_date">{galleryDate}</div>}
                </div>
            )}
            <Graphic
                className="galleryMeta_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
            <SocialShare
                title={title}
                social={[isAmp ? '' : 'url', 'facebook', 'messenger', 'viber', 'whatsapp']}
                shareUrl={shareUrl}
            />
        </GalleryMetaStyled>
    )
}

GalleryMeta.propTypes = {
    className: PropTypes.string,
    author: PropTypes.string,
    photoAuthor: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shareUrl: PropTypes.string,
    title: PropTypes.string
}

GalleryMeta.defaultProps = {
    className: undefined,
    author: undefined,
    photoAuthor: undefined,
    date: undefined,
    shareUrl: undefined,
    title: undefined
}

export default GalleryMeta
