/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import styles from './Head.style'

const getStringLength = str => str?.replace(/(<([^>]+)>)/gi, '').trim().length || 0

const HeadStyled = styled.section(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Head({ className, label, labelLink, title, titleSize, titleTagComponent: TitleTag, lead, ...rest }) {
    const labelHtmlProps = useInnerHtml(label)
    const titleHtmlProps = useInnerHtml(title)

    if (!title) {
        return null
    }

    return (
        <HeadStyled className={className} titleLength={getStringLength(title)} {...rest}>
            <div className="searchHead">
                {label && (
                    <HeadingLabel href={labelLink} hrefAs={labelLink} className="searchHead_label">
                        <span {...labelHtmlProps} />
                    </HeadingLabel>
                )}
                <TitleTag className="searchHead_title" {...titleHtmlProps} />
                {lead && (
                    <div className="articleHead_lead">
                        <HtmlContent data={lead} />
                    </div>
                )}
            </div>
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    title: PropTypes.string,
    titleSize: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    title: undefined,
    titleSize: undefined,
    titleTagComponent: 'h1',
    lead: undefined
}

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[CategoryHead]: ', error, componentStack)
    }
})
