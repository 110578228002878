import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Icon, PreparationIcon } from '../../../Icon'
import styles from './PreparationSteps.style'
import Step from './Step.component'

const PreparationStepsStyled = styled.section(props => ({ ...styles(props) }))

function PreparationSteps({ className, title, titleTagComponent: TitleTag, steps, ...rest }) {
    const uid = useUIDSeed()

    const stepList = useMemo(() => {
        const filteredSteps = steps.filter(Boolean).map((step, index) => ({ ...step, id: uid(index) }))

        return filteredSteps
    }, [steps, uid])

    if (!stepList?.length) {
        return null
    }

    return (
        <PreparationStepsStyled className={className} {...rest}>
            <div className="recipePreparation_head">
                <Icon className="recipePreparation_icon" icon={PreparationIcon} viewBox="0 0 48 48" />
                {title ? <TitleTag className="recipePreparation_title">{title}</TitleTag> : null}
            </div>
            <div className="sectionContent">
                {stepList.map((step, index) => (
                    <Step id={`prep-step-${index}`} key={step.id} step={index + 1} data={step} />
                ))}
            </div>
        </PreparationStepsStyled>
    )
}

PreparationSteps.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    steps: PropTypes.oneOfType([() => null, PropTypes.object])
}

PreparationSteps.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h2',
    steps: []
}

export default withTheme(PreparationSteps)
