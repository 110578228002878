import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useViewportWidth } from '@hmn/rtl-web-core/hooks'

import { Overall, overallVariants } from '../../../Comments/components'
import styles from './RateSummary.style'

const RateSummaryStyled = styled.div(props => ({ ...styles(props) }))

function RateSummary({ price, design, quality, overall, ...rest }) {
    const viewportWidth = useViewportWidth()
    if (!overall) {
        return null
    }
    return (
        <RateSummaryStyled viewportWidth={viewportWidth} {...rest}>
            <Overall
                value={overall}
                max={5}
                size={30}
                variant={overallVariants.BETA}
                label="Ukupno"
                labelRightWidth={80}
            />
            {price && (
                <div className="ratingItem">
                    <div className="ratingItem_value">{price}</div>
                    <div className="ratingItem_title">Cijena</div>
                </div>
            )}
            {design && (
                <div className="ratingItem">
                    <div className="ratingItem_value">{design}</div>
                    <div className="ratingItem_title">Dizajn</div>
                </div>
            )}
            {quality && (
                <div className="ratingItem">
                    <div className="ratingItem_value">{quality}</div>
                    <div className="ratingItem_title">Kvaliteta</div>
                </div>
            )}
        </RateSummaryStyled>
    )
}

RateSummary.propTypes = {
    overall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    design: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

RateSummary.defaultProps = {
    overall: undefined,
    price: undefined,
    design: undefined,
    quality: undefined
}

export default RateSummary
