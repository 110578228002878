/* eslint-disable quote-props, no-dupe-keys, max-len */
import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    width: '100%',
    position: 'relative',
    background: theme.colors.product.reviewSummary.background,
    marginBottom: toRem(20),
    '& .reviewSummary': {
        '&_content': {
            position: 'relative',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: toRem(theme.layoutDimension.product.content - 50),
            padding: toRems([60, 25, 40, 25]),
            [theme.breakpoints.up('md')]: {
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: toRems([40, 0, 40, 0])
            }
        },
        '&_graphic': {
            position: 'absolute',
            width: `calc( 100% - ${toRem(50)})`,
            left: toRem(25),
            height: toRem(40),
            top: 'auto',
            bottom: toRem(-20),
            [theme.breakpoints.up('md')]: {
                height: toRem(60),
                top: toRem(-30),
                bottom: 'auto',
                minWidth: toRem(450),
                width: `calc( 60% - ${toRem(50)})`,
                left: 0
            }
        },
        '&_badge': {
            marginBottom: toRem(25),
            [theme.breakpoints.up('md')]: {
                marginBottom: 0
            }
        },
        '&_line': {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block',
                margin: toRems([0, 10, 0, -30]),
                width: toRem(50),
                height: 1,
                background: theme.colors.heading.label.border.default
            }
        },
        '&_item': {
            marginBottom: toRem(30),
            marginLeft: toRem(20),
            [theme.breakpoints.up('md')]: {
                marginLeft: 0,
                marginBottom: 0
            }
        },
        '&_button': {
            fontSize: toRem(12),
            lineHeight: toRem(24),
            background: theme.colors.product.reviewSummary.background,
            '& .buttonArrowRight': {
                marginLeft: toRem(15)
            },
            '&:hover': {
                color: theme.colors.product.reviewSummary.text.white,
                backgroundColor: theme.colors.background.body.inverted
            }
        }
    }
})

export default style
