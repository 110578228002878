import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useBreakpoints, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../../../Button'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import { ProductRateSummary } from '..'
import styles from './Head.style'

const HeadStyled = styled.section(props => ({ ...styles(props) }))

function Head({
    className,
    label,
    brand,
    title,
    titleTagComponent: TitleTag,
    lead,
    image,
    imageWidth,
    imageHeight,
    alt,
    price,
    design,
    quality,
    overall,
    onOcijeniButtonClick,
    showOcijeniButton,
    reviewButton,
    ...rest
}) {
    const [isDesktop] = useBreakpoints('md')
    const labelHtmlProps = useInnerHtml(label)
    const brandHtmlProps = useInnerHtml(brand)
    const titleHtmlProps = useInnerHtml(title)
    const isAmp = useAmp()

    if (!title) {
        return null
    }

    return (
        <HeadStyled className={className} imageWidth={imageWidth} image={image} {...rest}>
            <div className="productHead">
                {label && (
                    <HeadingLabel className="productHead_label">
                        <span {...labelHtmlProps} />
                    </HeadingLabel>
                )}
                {brand && <div className="productHead_brand" {...brandHtmlProps} />}
                {title && <TitleTag className="productHead_title" {...titleHtmlProps} />}
                {lead && (
                    <>
                        <div className="productHead_lead">
                            <HtmlContent data={lead} />
                        </div>
                        <Media lessThan="md">
                            {image && (
                                <div className="productHero_wrapper_small">
                                    <div className="productHero">
                                        <Image
                                            variation={imageRatioVariants.CUSTOM_SQUARE}
                                            image={image}
                                            width={imageWidth}
                                            height={imageHeight}
                                            alt={alt || title}
                                            aspect={1.4}
                                            fullHeight
                                            preload
                                            lazyLoad={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </Media>
                    </>
                )}
                <div className="productHead_rate">
                    {!!overall && (
                        <ProductRateSummary overall={overall} price={price} design={design} quality={quality} />
                    )}
                    {showOcijeniButton && (
                        <Button
                            className="productHead_rateButton"
                            title="Ocijeni proizvod"
                            onClick={onOcijeniButtonClick}
                            buttonWidth={isDesktop ? 'auto' : '100%'}
                            variant={buttonVariants.BETA}>
                            Ocijeni proizvod
                        </Button>
                    )}
                    {reviewButton?.review && (
                        <Button
                            className="productHead_reviewButton"
                            target="_blank"
                            title={reviewButton?.button_title}
                            href={reviewButton?.url}
                            buttonWidth={isDesktop ? 'auto' : '100%'}
                            variant={buttonVariants.ALPHA}>
                            {reviewButton?.button_title}
                        </Button>
                    )}
                </div>
            </div>
            {/* <Media greaterThanOrEqual="md"> */}
            {image && !isAmp && (
                <div className="productHero_wrapper">
                    <div className="productHero">
                        <Image
                            variation={imageRatioVariants.CUSTOM_SQUARE}
                            image={image}
                            width={imageWidth}
                            height={imageHeight}
                            alt={alt || title}
                            aspect={1.4}
                            fullHeight
                            preload
                            lazyLoad={false}
                        />
                    </div>
                </div>
            )}
            {/* </Media> */}
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    brand: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    overall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    design: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onOcijeniButtonClick: PropTypes.func,
    showOcijeniButton: PropTypes.bool,
    reviewButton: PropTypes.arrayOf(PropTypes.shape({}))
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    brand: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    image: undefined,
    imageWidth: 800,
    imageHeight: 800,
    alt: undefined,
    overall: undefined,
    price: undefined,
    design: undefined,
    quality: undefined,
    onOcijeniButtonClick: undefined,
    showOcijeniButton: false,
    reviewButton: undefined
}
export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ProductHead]: ', error, componentStack)
    }
})
