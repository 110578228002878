import { toRem } from '../../../../helpers/theme'

const style = ({ theme, viewportWidth }) => {
    const config = {
        sm: {
            ratingItem: {
                flexDirection: 'column'
            }
        },
        md: {
            ratingItem: {
                flexDirection: 'row'
            }
        }
    }
    return {
        display: 'flex',
        '& .ratingItem': {
            fontWeight: theme.typography.fontWeight.bold,
            textTransform: 'uppercase',
            display: 'flex',
            flexDirection: config?.sm?.ratingItem?.flexDirection,
            marginLeft: viewportWidth > 370 ? toRem(20) : toRem(10),
            height: 30,
            [theme.breakpoints.up('md')]: {
                flexDirection: config?.md?.ratingItem?.flexDirection
            },
            '&_value': {
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.comments.item.text,
                fontSize: toRem(12),
                marginRight: toRem(6),
                lineHeight: 2
            },
            '&_title': {
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.text.alternative,
                fontSize: viewportWidth > 370 ? toRem(10) : toRem(9),
                letterSpacing: '0.25em',
                lineHeight: 1.8
            }
        }
    }
}

export default style
