import { useMemo } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import stripHtml from '@hmn/rtl-web-core/helpers/js/stripHtmlTags'
import truncate from '@hmn/rtl-web-core/helpers/js/truncateString'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useBreakpoints, useSeoImages } from '@hmn/rtl-web-core/hooks'

import {
    dateToSeo,
    ingredientsToSeo,
    organizationStructuredData,
    sitemapToBreadcrumbs,
    StructuredData
} from '../../../Seo'

dayjs.extend(duration)

const generateRecipeStructuredData = (
    item,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`
) => {
    const title = truncate(stripHtml(item?.title))
    const publishDate = dateToSeo(item?.published_at || item?.updated_at)
    const updateDate = dateToSeo(item?.updated_at)
    const description = parseSeoDescription(item?.description || item?.lead)

    const { publisher } = item?.extended_attributes || {}
    const prepTime = item?.preparation_time
        ? dayjs.duration({ minutes: item.preparation_time }).toISOString()
        : undefined
    const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'

    return {
        name: title,
        description,
        image: imageUrl,
        '@type': 'Recipe',
        prepTime,
        // @TODO: is there total time as a separate property?
        // totalTime: prepTime,
        yield: item?.servings,
        cookTime: prepTime,
        nutrition: {
            '@type': 'NutritionInformation',
            servingSize: item?.serving
        },
        author,
        publisher,
        recipeIngredient: ingredientsToSeo(Object.values(item?.ingredients || {})),
        recipeYield: item?.servings,
        inLanguage: 'Croatian',
        thumbnailUrl: imageUrl,
        dateCreated: publishDate,
        datePublished: publishDate,
        dateModified: updateDate
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RecipeSeo({ item, images, hasAmp, shareUrl, structuredDataOverwrite }) {
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)

    const { imageMeta } = useSeoImages(images || [], {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 600
    })

    const { seo, structuredData } = useMemo(() => {
        if (!item?.id) {
            return {}
        }
        const {
            google_title: googleTitle,
            mobile_title: mobileTitle,
            social_title: socialTitleInput
        } = item?.extended_attributes || {}

        const title = truncate(stripHtml(item?.title))
        const deviceTitle = (deviceType === 'mobile' && mobileTitle) || title
        const description = parseSeoDescription(item?.description || item?.lead)

        // @TODO: load author/source properly, for now override with zena.hr
        const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'

        const updateDate = dateToSeo(item?.updated_at)
        const itemHref =
            item?.sitemap?.href ||
            (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
            item?.href ||
            ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`

        const breadcrumbs = sitemapToBreadcrumbs(item?.taxons?.sitemap?.[0]?.path)

        const preparationSteps = item?.preparation_steps
            ?.sort((a, b) => a.position_group - b.position_group || a.position_step - b.position_step)
            ?.filter(Boolean)
            ?.map(step => {
                const prepStepTitle = !!step.title && stripHtml(step.title)
                const prepStepDesc = !!step.description && stripHtml(step.description)
                return {
                    '@type': 'HowToStep',
                    name: prepStepTitle || prepStepDesc,
                    text: 'Preheat the oven to 350 degrees F. Grease and flour a 9x9 inch pan.',
                    // eslint-disable-next-line max-len
                    url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}#prep-step-${step.position_group}-${step.position_step}`,
                    image: step.image || imageMeta[0]
                }
            })
        return {
            seo: {
                title: googleTitle || deviceTitle,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title: socialTitleInput || deviceTitle,
                    description,
                    type: 'website',
                    images: processOpenGraphImages(imageMeta, title),
                    updateTime: updateDate && new Date(updateDate)?.toISOString()
                },
                twitter: {
                    creator: author
                },
                robotsProps: {
                    maxImagePreview: 'large',
                    maxVideoPreview: 10
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'WebPage',
                    name: googleTitle || deviceTitle,
                    publisher: organizationStructuredData
                },
                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateRecipeStructuredData(item, imageMeta?.[0]?.url),
                    name: googleTitle || deviceTitle,
                    recipeInstructions: preparationSteps,

                    ...(structuredDataOverwrite || {})
                }
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, deviceType, shareUrl, structuredDataOverwrite, imageMeta])

    return (
        <>
            {seo && <NextSeo {...seo} />}
            {(structuredData || structuredDataOverwrite) && (
                <StructuredData data={structuredData} dataOverwrite={structuredDataOverwrite} />
            )}
        </>
    )
}

RecipeSeo.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string
}

RecipeSeo.defaultProps = {
    item: undefined,
    images: undefined,
    hasAmp: false,
    structuredDataOverwrite: undefined,
    shareUrl: undefined
}

export { generateRecipeStructuredData }
export default withErrorBoundary(RecipeSeo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RecipeSeo]: ', error, componentStack)
    }
})
