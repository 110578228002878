import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { ArrowRightIcon, Icon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './PrevNextButtons.style'

const PrevNextButtonsStyled = styled.div(props => ({ ...styles(props) }))

function PrevNextButtons({ className, previousHref, nextHref, ...rest }) {
    if (!previousHref && !nextHref) {
        return null
    }
    return (
        <PrevNextButtonsStyled className={className} {...rest}>
            {previousHref && (
                <Link href={previousHref}>
                    <button type="button" className="prevNextButtons_previous">
                        <Icon icon={ArrowRightIcon} /> <span>PRETHODNI</span>
                    </button>
                </Link>
            )}
            <Graphic
                className="prevNextButtons_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
            {nextHref && (
                <Link href={nextHref}>
                    <button type="button" className="prevNextButtons_next">
                        <span>SLJEDEĆI</span> <Icon icon={ArrowRightIcon} />
                    </button>
                </Link>
            )}
        </PrevNextButtonsStyled>
    )
}

PrevNextButtons.propTypes = {
    className: PropTypes.string,
    previousHref: PropTypes.string,
    nextHref: PropTypes.string
}

PrevNextButtons.defaultProps = {
    className: undefined,
    previousHref: undefined,
    nextHref: undefined
}

export default PrevNextButtons
