import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    '& .recipeIngredients_graphic': {
        height: 'auto',
        flexShrink: 0
    },
    '& .recipeIngredients_content': {
        padding: toRems([20, 0, 30, 15]),
        [theme.breakpoints.up('md')]: {
            padding: toRems([20, 0, 30, 40])
        }
    },
    '& .recipeIngredients_head': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: toRem(15),
        color: theme.colors.heading.basic.text.accent,
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(34)
        }
    },
    '& .recipeIngredients_title': {
        fontWeight: theme.typography.fontWeight.semiBold
    },
    '& .recipeIngredients_icon': {
        marginRight: toRem(5),
        fontSize: toRem(36),
        [theme.breakpoints.up('md')]: {
            marginRight: toRem(12),
            fontSize: toRem(48)
        }
    },
    '& .recipeIngredients_list': {
        paddingLeft: toRem(12)
    },
    '& .recipeIngredients_item': {
        marginBottom: toRem(12),
        fontSize: toRem(16),
        color: theme.colors.text.default,
        span: {
            color: theme.colors.text.default
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(25),
            fontSize: toRem(18)
        }
    },
    svg: {
        path: {
            fill: 'currentColor'
        }
    }
})

export default style
