import PropTypes from 'prop-types'

import { CommentsItem, commentsItemVariants } from '../../../Comments/components'
import { ProductRateSummary } from '..'

function RateList({ ratingItem: rate }) {
    return (
        <CommentsItem
            user={rate.owned_by_user_profile}
            date={rate.created_at}
            text={rate.extended_attributes?.form_answer_product_rating_comment}
            variant={commentsItemVariants.ALPHA}>
            <ProductRateSummary
                overall={rate.extended_attributes?.form_answer_product_rating_average}
                max={5}
                price={rate.extended_attributes?.form_answer_product_rating_price}
                design={rate.extended_attributes?.form_answer_product_rating_design}
                quality={rate.extended_attributes?.form_answer_product_rating_quality}
            />
        </CommentsItem>
    )
}

RateList.propTypes = {
    ratingItem: PropTypes.shape({
        owned_by_user_profile: PropTypes.shape({}),
        created_at: PropTypes.string,
        extended_attributes: PropTypes.shape({
            form_answer_product_rating_comment: PropTypes.string,
            form_answer_product_rating_average: PropTypes.number,
            form_answer_product_rating_price: PropTypes.number,
            form_answer_product_rating_design: PropTypes.number,
            form_answer_product_rating_quality: PropTypes.number
        })
    }).isRequired
}

export default RateList
