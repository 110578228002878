import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    padding: toRems([32, 25]),
    background: theme.colors.background.body.light,
    [theme.breakpoints.up('md')]: {
        padding: toRems([42, 50, 45])
    },
    [theme.breakpoints.down('sm')]: {
        margin: '0 calc(50% - 50vw)'
    },
    '& .recipeAdvice_graphic': {
        position: 'absolute',
        top: toRem(-18),
        [theme.breakpoints.up('md')]: {
            top: toRem(-30)
        }
    },
    '& .recipeAdvice_title': {
        margin: toRems([0, 0, 10, 0]),
        fontSize: toRem(18),
        [theme.breakpoints.up('md')]: {
            margin: toRems([0, 0, 20, 0]),
            fontSize: toRem(28)
        }
    },
    '& .recipeAdvice_content': {
        fontSize: toRem(16),
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(18)
        }
    }
})

export default style
