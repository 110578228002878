import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, imageWidth, image }) => ({
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row'
    },
    '& .productHead': {
        maxWidth: toRem(690),
        padding: toRems([0, 20]),
        [theme.breakpoints.up('md')]: {
            flex: 1,
            margin: image && 'auto',
            minWidth: toRem(450)
        },
        '&_label': {
            marginBottom: toRem(5),
            lineHeight: toRem(24),
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(45)
            }
        },
        '&_brand': {
            color: theme.colors.subtitle.default,
            fontSize: toRem(16),
            lineHeight: toRem(22),
            [theme.breakpoints.up('md')]: {
                lineHeight: toRem(28)
            }
        },
        '&_title': {
            margin: toRems([5, 0, 16]),
            fontSize: toRem(28),
            lineHeight: toRem(40),
            [theme.breakpoints.up('md')]: {
                margin: toRems([12, 0, 20]),
                fontSize: toRem(52),
                lineHeight: toRem(72)
            }
        },
        '&_lead p': {
            marginBottom: toRem(15),
            fontSize: toRem(20),
            fontWeight: theme.typography.fontWeight.extraLight,
            lineHeight: toRem(32),
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(35),
                fontSize: toRem(32),
                lineHeight: toRem(52)
            }
        },
        '&_rate': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        },
        '&_rateButton': {
            marginTop: 25,
            [theme.breakpoints.up('md')]: {
                marginTop: 0
            }
        },
        '&_reviewButton': {
            marginTop: toRem(20),
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                marginLeft: toRem(20)
            }
        }
    },
    '& .productHero': {
        position: 'relative',
        margin: 'auto',
        marginBottom: toRem(40),
        width: '50%',
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(0),
            width: '100%'
        },
        '&_wrapper': {
            maxWidth: toRem(450),
            margin: 'auto',
            zIndex: theme.zIndex.under,
            [theme.breakpoints.up('md')]: {
                zIndex: 0,
                flex: 1,
                flexBasis: toRem(imageWidth)
            },
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        '&_wrapper_small': {
            maxWidth: toRem(450),
            margin: 'auto',
            zIndex: theme.zIndex.under,
            [theme.breakpoints.up('md')]: {
                zIndex: 0,
                flex: 1,
                flexBasis: toRem(imageWidth)
            }
        }
    }
})

export default style
