import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { MetaButton, metaButtonVariants } from '../../../MetaButton'
import { SocialShare } from '../../../Social/components'
import styles from './Meta.style'

const ProductMetaStyled = styled.div(props => ({ ...styles(props) }))

function ProductMeta({
    author,
    date,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shareButtonHref,
    className,
    commentButtonHref,
    commentButtonValue,
    reviewButtonHref,
    reviewButtonValue,
    shareUrl,
    title,
    ...rest
}) {
    const articleDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])

    return (
        <ProductMetaStyled className={className} {...rest}>
            {(author || date) && (
                <div className="productMeta_inner">
                    {author && <div className="productMeta_author">{author}</div>}
                    {date && <div className="productMeta_date">{articleDate}</div>}
                </div>
            )}
            <SocialShare
                title={title}
                social={['url', 'facebook', 'messenger', 'viber', 'whatsapp']}
                shareUrl={shareUrl}
            />
            <Graphic
                className="productMeta_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
            {commentButtonHref && (
                <MetaButton
                    href={commentButtonHref}
                    iconValue={commentButtonValue}
                    variant={metaButtonVariants.SCORE}
                    title="ocjene"
                />
            )}
            {reviewButtonHref && (
                <MetaButton
                    iconValue={reviewButtonValue}
                    href={reviewButtonHref}
                    variant={metaButtonVariants.REVIEW}
                    title="recenzija"
                />
            )}
        </ProductMetaStyled>
    )
}

ProductMeta.propTypes = {
    className: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shareButtonHref: PropTypes.string,
    commentButtonHref: PropTypes.string,
    commentButtonValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviewButtonHref: PropTypes.string,
    reviewButtonValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shareUrl: PropTypes.string,
    title: PropTypes.string
}

ProductMeta.defaultProps = {
    className: undefined,
    author: undefined,
    date: undefined,
    shareButtonHref: undefined,
    commentButtonHref: undefined,
    commentButtonValue: 0,
    reviewButtonValue: 0,
    reviewButtonHref: undefined,
    shareUrl: undefined,
    title: undefined
}

export default ProductMeta
