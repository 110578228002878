// @TODO: Label component can have no lines variation instead doing it with css here
import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    paddingBottom: toRem(40),
    background: theme.colors.background.body.secondary,
    [theme.breakpoints.up('md')]: {
        paddingBottom: toRem(70),
        margin: toRems([50, 0])
    },
    '& .galleryContent_wrapper': {
        position: 'relative',
        maxWidth: toRem(1200),
        margin: 'auto',
        padding: toRems([0, 25])
    },
    '& .galleryContent_swiper': {
        margin: 'auto',
        maxWidth: toRem(900)
    },
    '& .galleryContent_head': {
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(15)
        }
    },
    '& .galleryContent_label': {
        margin: toRems([20, 0, 10]),
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            margin: toRems([0, 8, 0, 0]),
            '& .HeadingLabel_inner::before': {
                content: 'none'
            }
        },
        '& .HeadingLabel_inner::after': {
            content: 'none'
        }
    },
    '& .galleryContent_subtitle': {
        display: 'inline-block',
        color: theme.colors.subtitle.default,
        fontSize: toRem(16),
        lineHeight: 1.4
    },
    '& .galleryContent_title': {
        margin: toRems([4, 0, 10, 0]),
        color: theme.colors.heading.basic.text.default,
        fontSize: toRem(20),
        [theme.breakpoints.up('md')]: {
            margin: toRems([0, 0, 10, 0]),
            fontSize: toRem(24)
        }
    },
    '& .galleryContent_lead': {
        '& .css-0': {
            color: theme.colors.text.default,
            fontSize: toRem(16),
            lineHeight: 1.6,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(18)
            }
        }
    },
    '& .galleryContent_triangle': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    '& .galleryContent_graphic': {
        position: 'absolute',
        right: toRem(15),
        bottom: toRem(-40),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    '& .swiperNav_': {
        '&prev, &next': {
            position: 'absolute',
            top: toRem(300),
            right: '2%',
            transform: 'translateY(-50%)',
            color: theme.colors.text.default,
            zIndex: theme.zIndex.over,
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            svg: {
                path: {
                    fill: 'currentColor'
                }
            }
        },
        '&prev': {
            left: '2%',
            right: 'initial'
        },
        '&next': {
            right: '2%'
        }
    },
    '& .swiper-pagination': {
        position: 'absolute',
        zIndex: theme.zIndex.over,
        top: toRem(5),
        [theme.breakpoints.up('md')]: {
            top: toRem(450)
        }
    },
    '& .swiper-button-disabled': {
        opacity: 0,
        pointerEvents: 'none'
    },
    '& .swiper-pagination-bullet': {
        background: theme.colors.text.default,
        opacity: 1
    },
    '& .swiper-pagination-bullet-active': {
        background: theme.colors.text.accent
    }
})

export default style
