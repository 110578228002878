import { useMemo } from 'react'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import stripHtml from '@hmn/rtl-web-core/helpers/js/stripHtmlTags'
import truncate from '@hmn/rtl-web-core/helpers/js/truncateString'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useBreakpoints, useImageURL, useSeoImages } from '@hmn/rtl-web-core/hooks'

import { generateArticleStructuredData } from '../../../Article/components'
import { generateGalleryStructuredData } from '../../../Gallery/components'
import { generateProductStructuredData } from '../../../Product/components'
import { generateRecipeStructuredData } from '../../../Recipe/components'
import { dateToSeo, organizationStructuredData, sitemapToBreadcrumbs, StructuredData } from '../../../Seo'
import { generateVideoStructuredData } from '../../../Video/components'

const generateListingPageStructuredData = (
    item,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`
) => {
    const title = truncate(stripHtml(item?.title))
    const description = parseSeoDescription(item?.description)
    const publishDate = dateToSeo(item?.published_at || item?.updated_at)
    const updateDate = dateToSeo(item?.updated_at)
    const content = stripHtml(item?.content) || description
    const keywords = item?.breadcrumb
    return {
        name: title,
        image: imageUrl,
        '@type': 'CollectionPage',
        headline: truncate(title, 110, '...'),
        description: description || content,
        inLanguage: 'Croatian',
        thumbnailUrl: imageUrl,
        dateCreated: publishDate,
        datePublished: publishDate,
        dateModified: updateDate,
        publisher: organizationStructuredData,
        keywords,
        about: description || content
    }
}

const getTypeStructuredDataFunction = type => {
    switch (type) {
        case 'article':
            return generateArticleStructuredData
        case 'gallery':
            return generateGalleryStructuredData
        case 'recipe':
            return generateRecipeStructuredData
        case 'video':
            return generateVideoStructuredData
        case 'product':
            return generateProductStructuredData
        default:
            // console.log(`[CategorySeo]: Missing structured data for ${type} using article`)
            return generateArticleStructuredData
    }
}
const generateItemListStructuredData = (items, itemImages, category, name, description, maxItems = 50) =>
    items?.length > 0 && {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        name: truncate(stripHtml(name || category?.title || '')),
        description: parseSeoDescription(description || category?.description || ''),
        itemListOrder: 'https://schema.org/ItemListOrderAscending',
        numberOfItems: items?.length,
        // @TODO: check the max limit on number of articles in list
        itemListElement: items
            .slice(0, maxItems)
            ?.map((item, index) => {
                if (!item) {
                    return null
                }
                return {
                    '@type': 'ListItem',
                    position: index + 1,
                    item: {
                        url: !!item?.sitemap?.href && `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${item.sitemap.href}`,
                        ...getTypeStructuredDataFunction(item.type)(item, itemImages?.[index])
                    }
                }
            })
            .filter(Boolean)
    }

function Seo({ item, images, shareUrl, isWidgetLayout, page, structuredDataOverwrite }) {
    // @TODO: merge images once they're available directly through item
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)
    const { imageMeta } = useSeoImages(images, {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 630
    })

    const { articleImageIds } = useMemo(
        () => ({
            articleImageIds: (!isWidgetLayout && item?.articles?.map(article => article?.image?.id)) || []
        }),
        [item, isWidgetLayout]
    )

    const articleImageUrls = useImageURL(articleImageIds, 'custom-3-2', 1200, 800, 'jpg')

    const { seo, structuredData } = useMemo(() => {
        if (!item) {
            return {}
        }

        const {
            google_title: googleTitle,
            mobile_title: mobileTitle,
            social_title: socialTitleInput
        } = item?.extended_attributes || {}

        const title = truncate(stripHtml(item?.title))
        const deviceTitle = (deviceType === 'mobile' && mobileTitle) || title
        const description = parseSeoDescription(item?.description)

        // @TODO: load author/source properly, for now override with zena.hr
        const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'

        const updateDate = dateToSeo(item?.updated_at)

        const itemHref =
            item?.sitemap?.href ||
            (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
            item?.href ||
            ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        const canonical =
            url?.split('//')?.[1]?.split('/')?.[1] === 'naslovnica' ? process.env.NEXT_PUBLIC_APP_ROOT_ZENA : url

        const articles = item?.articles
        const breadcrumbs = sitemapToBreadcrumbs(item?.path)
        const isInfoPage = item?.path?.some(p => p.slug === 'info')
        const isCollectionPage = (articles?.length > 0 || isWidgetLayout) && !isInfoPage

        return {
            seo: {
                title: `${googleTitle || deviceTitle}${page > 1 ? ` | Stranica ${page}` : ''}`,
                description,
                canonical,
                openGraph: {
                    url: canonical,
                    title: `${socialTitleInput || deviceTitle}${page > 1 ? ` | Stranica ${page}` : ''}`,
                    description,
                    type: 'website',
                    images: processOpenGraphImages(imageMeta, title),
                    updateTime: updateDate
                },
                twitter: {
                    creator: author
                },
                robotsProps: {
                    maxImagePreview: 'large',
                    maxVideoPreview: 10
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                isCollectionPage && {
                    '@context': 'https://schema.org',
                    '@type': 'WebPage',
                    name: title,

                    publisher: organizationStructuredData
                },
                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateListingPageStructuredData(item, imageMeta?.[0]?.url, item, title, description),
                    '@type': isCollectionPage ? 'CollectionPage' : 'WebPage',
                    primaryImageOfPage: imageMeta?.[0]?.url,
                    mainEntityOfPage: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`,
                    name: `${googleTitle || deviceTitle}${page > 1 ? ` | Stranica ${page}` : ''}`,
                    ...(structuredDataOverwrite || {})
                },
                !isWidgetLayout && articles?.length > 0 && generateItemListStructuredData(articles, articleImageUrls)
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, deviceType, imageMeta, shareUrl, isWidgetLayout, articleImageUrls, structuredDataOverwrite, page])
    return (
        <>
            {seo && <NextSeo {...seo} />}
            {structuredData && <StructuredData data={structuredData} />}
        </>
    )
}
Seo.propTypes = {
    page: PropTypes.number,
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string,
    isWidgetLayout: PropTypes.bool
}

Seo.defaultProps = {
    page: 0,
    item: undefined,
    images: undefined,
    structuredDataOverwrite: undefined,
    hasAmp: false,
    shareUrl: undefined,
    isWidgetLayout: false
    // @TODO: add isSummaryPage bool
}

export { generateItemListStructuredData, generateListingPageStructuredData }
export default withErrorBoundary(Seo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[CategorySeo]: ', error, componentStack)
    }
})
