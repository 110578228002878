// @TODO: Revisit props real data is connected, when form functionality is implemented
import { useCallback, useRef } from 'react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { useBaseApiURL, useModal } from '@hmn/rtl-web-core/hooks'

import { submitAnswer } from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'
import { Button, buttonVariants } from '../../../Button'
import { CommentsAvatar, CommentsRateItem, Overall, overallVariants } from '../../../Comments/components'
import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { Icon, UnionIcon } from '../../../Icon'
import { ProfilePopup } from '../../../ProfilePopup'
import styles from './RateForm.style'

const RateFormStyled = styled.div(props => ({ ...styles(props) }))

const requiredValidator = value => {
    let error
    if (!value) {
        error = 'Polje je obavezno'
    }
    return error
}

function RateForm({ className, entityId, formId, onComplete, ...rest }) {
    const { isValid: profileIsValid, profile } = useUser()
    const { open: openModal } = useModal('profile-modal')

    const baseApiURL = useBaseApiURL()

    const rateForm = useRef()

    // @TODO: when real data is connected include this
    // if (!user) {
    //     return null
    // }

    const onSubmit = useCallback(
        async (values, actions) => {
            if ((profile && !profileIsValid) || !profile) {
                openModal()
                actions.setSubmitting(false)
                return
            }

            const answer = await submitAnswer(
                formId,
                'products',
                entityId,
                values,
                baseApiURL,
                process.env.NEXT_PUBLIC_HUMAN_API_READONLY_TOKEN_ZENA
            )

            if (answer?.error) {
                actions.setSubmitting(false)
                return
            }
            actions.resetForm()
            actions.setSubmitting(false)
            if (typeof onComplete === 'function') {
                onComplete(answer)
            }
        },
        [profile, profileIsValid, openModal, onComplete, baseApiURL, entityId, formId]
    )

    return (
        <RateFormStyled className={className} {...rest}>
            <ProfilePopup profileModalUid="profile-modal" />
            <CommentsAvatar user={profile} imageWidth={70} imageHeight={70} />
            <Formik
                innerRef={rateForm}
                onSubmit={onSubmit}
                initialValues={{
                    comment: '',
                    price: null,
                    design: null,
                    quality: null
                }}>
                {({ values, isSubmitting, isValid, dirty }) => {
                    const overall = Math.round(((values.price + values.design + values.quality) / 3) * 10) / 10

                    return (
                        <Form className="rateForm">
                            <div className="rateForm_input">
                                <Field name="comment" validate={requiredValidator}>
                                    {({ field }) => (
                                        <textarea
                                            className="rateForm_input_item"
                                            placeholder="Unesi komentar..."
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="rateForm_tip">
                                Molimo te da ocjenjuješ samo proizvode koje si isprobala.
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                <br /> Budi konkretna i informativna, općenite komentare tipa 'htjela bih ovaj proizvod'
                                će biti obrisan.
                            </div>
                            <div className="rateForm_bottom">
                                <div className="rateForm_bottom_items">
                                    <Field name="price" validate={requiredValidator}>
                                        {({ form: { setFieldValue } }) => (
                                            <CommentsRateItem
                                                title="cijena"
                                                name="price"
                                                value={values.price}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    <Field name="design" validate={requiredValidator}>
                                        {({ form: { setFieldValue } }) => (
                                            <CommentsRateItem
                                                title="dizajn"
                                                name="design"
                                                value={values.design}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    <Field name="quality" validate={requiredValidator}>
                                        {({ form: { setFieldValue } }) => (
                                            <CommentsRateItem
                                                title="kvaliteta"
                                                name="quality"
                                                value={values.quality}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className="rateForm_bottom_submit">
                                    <Graphic
                                        className="graphics"
                                        variant={graphicVariants.ETA}
                                        fillVariant={graphicFillVariants.ALTERNATIVE}
                                        widthMd={60}
                                    />
                                    <Overall
                                        size={70}
                                        text={`${overall}`}
                                        value={overall}
                                        variant={overallVariants.ALPHA}
                                        max={5}
                                        label="Ukupno"
                                    />
                                    <Button
                                        type="submit"
                                        title="Ocijeni proizvod"
                                        disabled={!isValid || !dirty}
                                        className="rateForm_bottom_submit_button"
                                        variant={buttonVariants.BETA}
                                        loading={isSubmitting}
                                        iconComponent={<Icon icon={UnionIcon} />}>
                                        Ocijeni proizvod
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </RateFormStyled>
    )
}

RateForm.propTypes = {
    className: PropTypes.string,
    onComplete: PropTypes.func,
    entityId: PropTypes.string,
    formId: PropTypes.string.isRequired
}

RateForm.defaultProps = {
    className: undefined,
    onComplete: undefined,
    entityId: undefined
}

export default RateForm
