// @TODO: Fix issue with image (width, height, ratio)
/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import styles from './Item.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

function Item({ item, className, ...rest }) {
    if (!item) {
        return null
    }

    return (
        <ItemStyled className={className} {...rest}>
            {item.image && (
                <div id={item.id} className="galleryItem_wrapper">
                    <Graphic
                        className="galleryItem_background"
                        variant={graphicVariants.THETA}
                        fillVariant={graphicFillVariants.ALTERNATIVE}
                    />
                    <Image
                        classNameProgressive="galleryItem_imageProgressive"
                        className="galleryItem_image"
                        image={item.image}
                        variation={imageRatioVariants.CUSTOM_ORIGINAL}
                        width={1600}
                        height={940}
                        alt={item.alt || item.title}
                    />
                </div>
            )}
            {(item.title || item.intro) && (
                <div className="galleryItem_desc">
                    {item.title && <HtmlContent data={item.title} className="galleryItem_title" />}
                    {item.intro && <HtmlContent data={item.intro} className="galleryItem_intro" />}
                </div>
            )}
        </ItemStyled>
    )
}

Item.propTypes = {
    className: PropTypes.string,
    item: PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        title: PropTypes.string,
        intro: PropTypes.string,
        alt: PropTypes.string,
        id: PropTypes.string
    })
}

Item.defaultProps = {
    className: undefined,
    item: undefined
}

export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GalleryItem]: ', error, componentStack)
    }
})
