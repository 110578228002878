import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { HtmlContent } from '../../../HtmlContent'
import styles from './Step.style'

const StepStyled = styled.div(props => ({ ...styles(props) }))

function Step({ step, data, ...rest }) {
    const { description } = data

    return (
        <StepStyled {...rest}>
            {step && (
                <div className="stepOrder">
                    {step < 10 ? '0' : ''}
                    {step}.
                </div>
            )}
            {description ? (
                <div className="stepContent">
                    <HtmlContent className="stepContent_description" data={description} />
                    <Graphic
                        className="stepContent_graphic"
                        variant={graphicVariants.ETA}
                        fillVariant={graphicFillVariants.ALTERNATIVE}
                    />
                </div>
            ) : null}
        </StepStyled>
    )
}

Step.propTypes = {
    step: PropTypes.number,
    data: PropTypes.oneOfType([() => null, PropTypes.object])
}

Step.defaultProps = {
    step: undefined,
    data: undefined
}

export default withTheme(
    withErrorBoundary(Step, {
        FallbackComponent: () => null,
        onError(error, componentStack) {
            // eslint-disable-next-line no-console
            console.error('[RecipePreparationSteps]: ', error, componentStack)
        }
    })
)
