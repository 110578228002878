import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    width: '100%',
    margin: toRems([40, 10, 40, 10]),
    [theme.breakpoints.up('md')]: {
        maxWidth: toRem(1100),
        margin: toRems([20, 'auto', 70]),
        display: 'flex',
        flexDirection: 'row',
        background: theme.colors.background.body.light
    },
    '& .recipeHead': {
        marginBottom: toRem(30),
        padding: toRems([0, 25]),
        [theme.breakpoints.up('md')]: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            margin: 'auto',
            width: '50%',
            padding: toRems([0, 50, 0, 25])
        }
    },
    '& .recipeHead_inner': {
        width: '100%',
        maxWidth: toRem(500)
    },
    '& .recipeHead_label': {
        marginBottom: toRem(12),
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(40)
        },
        '& .HeadingLabel_inner': {
            [theme.breakpoints.down('sm')]: {
                '&::after': {
                    content: 'none'
                }
            }
        }
    },
    '& .recipeHead_title': {
        margin: toRems([10, 0, 16]),
        color: theme.colors.text.default,
        [theme.breakpoints.up('md')]: {
            margin: toRems([10, 0, 26]),
            fontSize: toRem(52)
        }
    },
    '& .css-0': {
        fontSize: toRem(20),
        fontWeight: theme.typography.fontWeight.extraLight,
        lineHeight: 1.6,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(32)
        }
    },
    '& .recipeHead_info': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: toRem(34)
    },
    '& .recipeHero_wrapper': {
        maxWidth: toRem(1250),
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '50%'
        }
    },
    '& .recipeHero': {
        position: 'relative'
    },
    '& .imageDecoration_triangle': {
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    '& .imageDecoration_graphic': {
        position: 'absolute',
        right: toRem(15),
        bottom: toRem(-20),
        [theme.breakpoints.up('md')]: {
            right: toRem(15),
            bottom: toRem(-25)
        }
    }
})

export default style
