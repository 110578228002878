import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: toRem(20),
    '&:last-of-type': {
        '& .stepContent_graphic': {
            display: 'none'
        }
    },
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(25)
    },
    '& .step': {
        '&Order': {
            position: 'absolute',
            fontFamily: theme.typography.fontFamilyAlt,
            fontStyle: theme.typography.font.style.italic,
            fontSize: toRem(18),
            color: theme.colors.list.decimal.default,
            [theme.breakpoints.up('md')]: {
                top: toRem(-8),
                fontSize: toRem(36)
            }
        },
        '&Content': {
            position: 'relative',
            width: '100%',
            fontSize: toRem(18),
            paddingLeft: toRem(35),
            [theme.breakpoints.up('md')]: {
                paddingLeft: toRem(75)
            },
            '& .css-1iokh19': {
                marginBottom: toRem(20),
                [theme.breakpoints.up('md')]: {
                    marginBottom: toRem(32)
                }
            },
            '&_graphic': {
                width: '100%'
            },
            '& .css-0': {
                fontSize: toRem(18),
                fontWeight: 500
            }
        }
    }
})

export default style
