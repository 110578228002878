/* eslint-disable quote-props, no-dupe-keys, max-len */
import { toRem } from '../../../../helpers/theme'

const style = ({ theme, titleMaxWidth, variant }) => {
    const config = {
        variants: {
            alpha: {
                title: {
                    fontSize: toRem(24),
                    lineHeight: toRem(24)
                },
                icon: {
                    color: theme.colors.product.reviewSummary.icon.default,
                    marginRight: toRem(10)
                }
            },
            beta: {
                title: {
                    fontSize: toRem(36),
                    lineHeight: toRem(24)
                },
                icon: {
                    color: theme.colors.product.reviewSummary.icon.highlight,
                    marginRight: toRem(20)
                }
            }
        }
    }

    return {
        width: 'auto',
        display: 'inline-block',
        flex: '1 1 auto',
        '& > *': {
            verticalAlign: 'middle'
        },
        '& .icon': {
            color: config.variants[variant]?.icon.color,
            marginRight: config.variants[variant]?.icon.marginRight,
            path: {
                fill: 'currentColor'
            }
        },
        '& .content': {
            display: 'inline-flex',
            flexDirection: 'column',
            width: 'auto'
        },
        '& .value': {
            display: 'block',
            fontFamily: theme.typography.fontFamilyAlt,
            color: theme.colors.product.reviewSummary.text.highlight,
            fontSize: config.variants[variant]?.title.fontSize,
            lineHeight: config.variants[variant]?.title.lineHeight,
            fontStyle: 'italic',
            fontWeight: theme.typography.fontWeight.bold
        },
        '& .title': {
            display: 'block',
            width: 'auto',
            maxHeight: toRem(30),
            fontFamily: theme.typography.fontFamily,
            color: theme.colors.product.reviewSummary.text.default,
            fontSize: toRem(10),
            lineHeight: toRem(14),
            fontWeight: theme.typography.fontWeight.bold,
            textTransform: 'uppercase',
            letterSpacing: '0.2em',
            [theme.breakpoints.up('md')]: {
                maxWidth: toRem(titleMaxWidth)
            }
        }
    }
}

export default style
