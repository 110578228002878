// @TODO: Fix issue with image (width, height, ratio), setup swiper navigation and other swiper fixes
/* eslint-disable camelcase, max-len */
import { Children, isValidElement, useEffect, useMemo, useRef } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'
import SwiperCore, { Keyboard, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicFillVariants, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { ChevronLeftIcon, Icon } from '../../../Icon'
import styles from './Content.style'

SwiperCore.use([Navigation, Keyboard])
const ContentStyled = styled.section(props => ({ ...styles(props) }))

function Content({
    className,
    activeIndex,
    onChange,
    label,
    subtitle,
    title,
    titleTagComponent: TitleTag,
    lead,
    children,
    ...rest
}) {
    const swiperComponent = useRef()
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])
    const uid = useUIDSeed()
    const isAmp = useAmp()

    const labelHtmlProps = useInnerHtml(label)
    const subtitleHtmlProps = useInnerHtml(subtitle)
    const titleHtmlProps = useInnerHtml(title)

    const params = useMemo(
        () => ({
            slidesPerView: 1,
            initialSlide: activeIndex,
            autoHeight: true
        }),
        [activeIndex]
    )

    useEffect(() => {
        // swiperjs needs to be updated on each prop/params change
        // that includes initial component mount because of dynamic props depending on variant and/or resolution
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.params = {
                ...swiper.params,
                ...params
            }
            // @NOTE: empty area under image on gallery reload issue solution hack
            setTimeout(() => {
                swiper.update()
            }, 100)
        }
    }, [swiperComponent, itemElements, params])

    useEffect(() => {
        swiperComponent?.current?.swiper?.slideTo(activeIndex)
    }, [activeIndex])

    if (!itemElements?.length) {
        return null
    }

    const nextSlide = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slideNext()
        }
    }

    return (
        <ContentStyled className={className} {...rest}>
            <div className="galleryContent_wrapper">
                <Swiper
                    ref={swiperComponent}
                    followFinger={false}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: true,
                        pageUpDown: false
                    }}
                    onSlideChange={swiper => onChange(swiper.activeIndex)}
                    navigation={{
                        prevEl: '.swiperNav_prev',
                        nextEl: '.swiperNav_next'
                    }}
                    pagination={{
                        el: '.swiper-pagination',
                        dynamicBullets: true
                    }}
                    className="galleryContent_swiper"
                    {...params}>
                    {itemElements.map((child, index) => {
                        const { item, id } = child?.props || {}
                        return (
                            <SwiperSlide
                                onClick={() => {
                                    nextSlide()
                                }}
                                key={uid(id || item?.id || index)}>
                                {child}
                            </SwiperSlide>
                        )
                    })}
                    {isAmp ? null : <div className="swiper-pagination" />}
                    <div className="galleryContent_head">
                        {label && (
                            <HeadingLabel className="galleryContent_label">
                                <span {...labelHtmlProps} />
                            </HeadingLabel>
                        )}
                        {subtitle && <div className="galleryContent_subtitle" {...subtitleHtmlProps} />}
                        {title && <TitleTag className="galleryContent_title" {...titleHtmlProps} />}
                        {lead && <HtmlContent data={lead} className="galleryContent_lead" />}
                    </div>
                </Swiper>
                {isAmp ? null : (
                    <>
                        <button className="swiperNav_prev" type="button">
                            <Icon rotate={180} icon={ChevronLeftIcon} viewBox="0 0 64 64" size={64} />
                        </button>
                        <button className="swiperNav_next" type="button">
                            <Icon viewBox="0 0 64 64" icon={ChevronLeftIcon} size={64} />
                        </button>
                    </>
                )}
            </div>
            <Triangle className="galleryContent_triangle" />
            <Graphic
                className="galleryContent_graphic"
                variant={graphicVariants.ALPHA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
        </ContentStyled>
    )
}

Content.propTypes = {
    className: PropTypes.string,
    activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string
}

Content.defaultProps = {
    className: undefined,
    activeIndex: 0,
    onChange: undefined,
    label: undefined,
    subtitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined
}

export default withErrorBoundary(Content, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GalleryContent]: ', error, componentStack)
    }
})
