import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Pudge } from '@hmn/rtl-web-core/controllers'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicVariants } from '../../../Decoration/components'
import { Icon, IngredientsIcon } from '../../../Icon'
import styles from './Ingredients.style'

const IngredientsStyled = styled.section(props => ({ ...styles(props) }))

function Ingredients({ className, title, titleTagComponent: TitleTag, ingredients, ...rest }) {
    const uid = useUIDSeed()
    const ingredientList = useMemo(
        () => ingredients.reduce((all, group) => [...all, ...(group?.items || [group] || [])], []),
        [ingredients]
    )

    if (!ingredientList.length) {
        return null
    }

    return (
        <IngredientsStyled className={className} {...rest}>
            <Graphic className="recipeIngredients_graphic" widthXs={25} widthMd={60} variant={graphicVariants.THETA} />
            <div className="recipeIngredients_content">
                <div className="recipeIngredients_head">
                    <Icon className="recipeIngredients_icon" icon={IngredientsIcon} viewBox="0 0 48 48" />
                    {title ? <TitleTag className="recipeIngredients_title">{title}</TitleTag> : null}
                </div>
                <div className="recipeIngredients_list">
                    {ingredientList.map(item => {
                        const name = item.ingredient?.name || item.name
                        return (
                            <div key={uid(item)} className="recipeIngredients_item">
                                {item.quantity ? (
                                    <>
                                        <strong>
                                            {item.quantity} {item.unit}
                                        </strong>{' '}
                                    </>
                                ) : null}
                                <Pudge hook={useInnerHtml} props={[name]}>
                                    {nameHtmlProps => <span {...nameHtmlProps} />}
                                </Pudge>
                            </div>
                        )
                    })}
                </div>
            </div>
        </IngredientsStyled>
    )
}

Ingredients.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    ingredients: PropTypes.oneOfType([() => null, PropTypes.object])
}

Ingredients.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h2',
    ingredients: []
}

export default Ingredients
