import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    marginBottom: toRem(10),
    '& .sectionInfo': {
        position: 'relative',
        marginRight: toRem(26),
        fontSize: toRem(14),
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: toRem(30),
            fontSize: toRem(16)
        }
    },
    '& .sectionInfo_title': {
        marginRight: toRem(5),
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.text.alternative,
        '&::before': {
            content: '""',
            flexShrink: 0,
            margin: toRems([0, 10, 0, 0]),
            width: toRem(30),
            height: 1,
            background: theme.colors.text.alternative
        }
    },
    '& .sectionInfo_content': {
        fontWeight: theme.typography.fontWeight.semiBold,
        color: theme.colors.text.default,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginLeft: toRem(40),
            lineHeight: 1
        }
    }
})

export default style
