import PropTypes from 'prop-types'

import { CommentsItem, commentsItemVariants } from '../../../Comments/components'
import { ProductRateSummary } from '..'

function ReviewList({ review }) {
    return (
        <CommentsItem
            user={review.owned_by_user_profile}
            date={review.created_at}
            text={review.extended_attributes?.form_answer_answer_body?.comment.replaceAll('< b>', '</b>')}
            variant={commentsItemVariants.ALPHA}>
            <ProductRateSummary overall={review.extended_attributes?.form_answer_product_review_rating} max={5} />
        </CommentsItem>
    )
}

ReviewList.propTypes = {
    review: PropTypes.shape({
        owned_by_user_profile: PropTypes.shape({}),
        created_at: PropTypes.string,
        extended_attributes: PropTypes.shape({
            form_answer_answer_body: PropTypes.shape({
                comment: PropTypes.string
            }),
            form_answer_product_review_rating: PropTypes.string
        })
    }).isRequired
}

export default ReviewList
