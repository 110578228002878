/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml, usePluralize } from '@hmn/rtl-web-core/hooks'

import isNumeric from '../../../../helpers/utils/isNumeric'
import { Graphic, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import { RecipeInfo } from '../Info'
import styles from './Head.style'

const HeadStyled = styled.section(props => ({ ...styles(props) }))

function Head({
    className,
    label,
    labelLink,
    labelTitle,
    title,
    titleTagComponent: TitleTag,
    lead,
    image,
    imageWidth,
    imageHeight,
    alt,
    servings,
    prepTime,
    prepTimeUnit,
    ...rest
}) {
    const labelHtmlProps = useInnerHtml(label)
    const titleHtmlProps = useInnerHtml(title)
    const persons = usePluralize('%{c} osoba |||| %{c} osobe |||| %{c} osoba', parseInt(servings, 10))

    return (
        <HeadStyled className={className} imageWidth={imageWidth} {...rest}>
            <div className="recipeHead">
                <div className="recipeHead_inner">
                    {label && (
                        <HeadingLabel
                            href={labelLink}
                            hrefAs={labelLink}
                            title={labelTitle}
                            className="recipeHead_label">
                            <span {...labelHtmlProps} />
                        </HeadingLabel>
                    )}
                    {title && <TitleTag className="recipeHead_title" {...titleHtmlProps} />}
                    {lead && <HtmlContent className="recipeHead_lead" data={lead} />}
                    {(servings || prepTime) && (
                        <div className="recipeHead_info">
                            {isNumeric(servings) && servings > 0 && <RecipeInfo label="Broj osoba" content={persons} />}
                            {isNumeric(prepTime) && prepTime > 0 && (
                                <RecipeInfo label="Vrijeme pripreme" content={`${prepTime} ${prepTimeUnit}`} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {image && (
                <div className="recipeHero_wrapper">
                    <div className="recipeHero">
                        <Image
                            image={image}
                            variation={imageRatioVariants.CUSTOM_SQUARE}
                            width={imageWidth}
                            height={imageHeight}
                            alt={alt || title}
                            fullHeight
                            preload
                            lazyLoad={false}
                        />
                        <Triangle className="imageDecoration_triangle" />
                        <Graphic className="imageDecoration_graphic" variant={graphicVariants.ALPHA} />
                    </div>
                </div>
            )}
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    labelTitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    servings: PropTypes.number,
    prepTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prepTimeUnit: PropTypes.string
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    labelTitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    image: undefined,
    imageWidth: 600,
    imageHeight: 600,
    alt: undefined,
    servings: undefined,
    prepTime: undefined,
    prepTimeUnit: undefined
}

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RecipeHead]: ', error, componentStack)
    }
})
