import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& .productMeta': {
        '&_inner': {
            marginRight: toRem(10)
        },
        '&_author': {
            fontSize: toRem(14),
            lineHeight: 1.4,
            fontWeight: theme.typography.fontWeight.bold,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(16)
            }
        },
        '&_date': {
            color: theme.colors.text.alternative,
            fontSize: toRem(16),
            [theme.breakpoints.down('sm')]: {
                lineHeight: 1.4,
                fontSize: toRem(14)
            }
        },
        '&_graphic': {
            width: '100%'
        }
    }
})

export default style
