import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    // position: 'relative',
    paddingTop: toRem(20),
    [theme.breakpoints.up('md')]: {
        // padding: toRems([35, 0, 0])
    },
    '& .recipePreparation_head': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: toRem(15),
        color: theme.colors.heading.basic.text.accent,
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(34)
        }
    },
    '& .recipePreparation_title': {
        fontWeight: theme.typography.fontWeight.semiBold
    },
    '& .recipePreparation_icon': {
        marginRight: toRem(5),
        fontSize: toRem(36),
        [theme.breakpoints.up('md')]: {
            marginRight: toRem(12),
            fontSize: toRem(48)
        }
    },
    '& .section': {
        '&Head': {
            display: 'none',
            width: '100%',
            margin: toRems([0, 0, 25, 0]),
            [theme.breakpoints.up('md')]: {
                display: 'block',
                margin: toRems([0, 0, 45, 0])
            }
        },
        '&Title': {
            display: 'none',
            position: 'relative',
            zIndex: theme.zIndex.over,
            lineHeight: 1.1,
            [theme.breakpoints.up('md')]: {
                display: 'block'
            }
        },
        '&Info': {
            fontSize: toRem(16),
            lineHeight: 1.19
        },
        '&Content': {
            position: 'relative',
            width: '100%'
        }
    },
    svg: {
        path: {
            fill: 'currentColor'
        }
    }
})

export default style
