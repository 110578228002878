import { useMemo } from 'react'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import stripHtml from '@hmn/rtl-web-core/helpers/js/stripHtmlTags'
import truncate from '@hmn/rtl-web-core/helpers/js/truncateString'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useBreakpoints, useSeoImages } from '@hmn/rtl-web-core/hooks'

import { dateToSeo, organizationStructuredData, sitemapToBreadcrumbs, StructuredData } from '../../../Seo'

const generateProductStructuredData = (
    item,
    ratingList,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`
) => {
    const description = parseSeoDescription(item?.description || item?.lead)
    const title = truncate(stripHtml(item?.title))
    const ratingAverage = item?.extended_attributes?.product_rating_average
    const reviewAverage = item?.extended_attributes?.product_review_average
    const reviewList = item?.reviewList || []
    return {
        name: title,
        description,
        image: imageUrl,
        '@type': 'Product',
        brand: item?.brand?.name,
        aggregateRating: (ratingAverage || reviewAverage) && {
            '@type': 'AggregateRating',
            ratingValue: ratingAverage || reviewAverage,
            reviewCount: ratingAverage ? ratingList.length : reviewList.length
        },
        category: Object.values(item?.taxons?.sitemap?.[0]?.path || {})
            .map(path => path.title)
            ?.join(' > '),
        productId: item?.id
    }
}

const generateProductRateStructuredData = rateTaxonRead => {
    const {
        extended_attributes: extendedAttributes,
        title,
        slug,
        created_at: dateCreated,
        form_answer_product_rating_comment: reviewBody
    } = rateTaxonRead
    const { form_answer_product_rating_average: averageRating } = extendedAttributes
    const userName = slug?.split('-')?.[1] || ''
    const isAnonymous = !userName || userName === 'anym'
    if (!averageRating || !reviewBody) {
        return null
    }

    return {
        '@type': 'Review',
        reviewRating: {
            '@type': 'Rating',
            ratingValue: averageRating
        },
        name: title,
        author: {
            '@type': 'Person',
            name: isAnonymous ? 'Anonimni korisnik' : userName
        },
        datePublished: dateToSeo(dateCreated),
        reviewBody
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ProductSeo({ ratingList, item, images, hasAmp, shareUrl, structuredDataOverwrite }) {
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)

    const { imageMeta } = useSeoImages(images, {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 630
    })

    const rates = ratingList.filter(rate => rate?.extended_attributes?.form_answer_product_rating_average)

    const { seo, structuredData } = useMemo(() => {
        if (!item.id) {
            return {}
        }
        const {
            google_title: googleTitle,
            mobile_title: mobileTitle,
            social_title: socialTitleInput
        } = item?.extended_attributes || {}

        const title = truncate(stripHtml(item?.title))
        const deviceTitle = (deviceType === 'mobile' && mobileTitle) || title
        const description = parseSeoDescription(item?.description || item?.lead)

        const updateDate = dateToSeo(item?.updated_at)

        const itemHref =
            item?.sitemap?.href ||
            (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
            item?.href ||
            ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`

        const breadcrumbs = sitemapToBreadcrumbs(item?.taxons?.sitemap?.[0]?.path)
        return {
            seo: {
                title: googleTitle || deviceTitle,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title: socialTitleInput || deviceTitle,
                    description,
                    type: 'website',
                    images: processOpenGraphImages(imageMeta, title),
                    updateTime: updateDate
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'WebPage',
                    name: googleTitle || deviceTitle,

                    publisher: organizationStructuredData
                },

                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateProductStructuredData(item, ratingList, imageMeta?.[0]?.url),
                    name: googleTitle || deviceTitle,
                    review: rates?.map(generateProductRateStructuredData).filter(Boolean) || [],

                    ...(structuredDataOverwrite || {})

                    /* aggregateRating: rates.length > 0 && {
                        '@type': 'AggregateRating',
                        ratingValue:
                            rates
                                ?.map(rate => rate?.extended_attributes?.form_answer_product_rating_average)
                                .reduce((acc, rate) => acc + rate, 0) / rates.length,
                        reviewCount: rates.length
                    } */
                }
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, deviceType, imageMeta, shareUrl, structuredDataOverwrite, rates, ratingList])

    return (
        <>
            {seo && <NextSeo {...seo} />}
            {(structuredData || structuredDataOverwrite) && (
                <StructuredData data={structuredData} dataOverwrite={structuredDataOverwrite} />
            )}
        </>
    )
}

ProductSeo.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string,
    ratingList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

ProductSeo.defaultProps = {
    item: undefined,
    structuredDataOverwrite: undefined,
    images: undefined,
    hasAmp: false,
    shareUrl: undefined
}

export { generateProductStructuredData }
export default withErrorBoundary(ProductSeo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ProductSeo]: ', error, componentStack)
    }
})
