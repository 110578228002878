import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button } from '../../../Button'
import { Graphic, graphicVariants } from '../../../Decoration/components'
import {
    ArrowRightIcon,
    Icon,
    ReviewerBadgeIcon,
    ReviewThumbsUpIcon,
    ShoppingBagIcon,
    ThumbsUpIcon
} from '../../../Icon'
import { ProductReviewSummaryItem, reviewSummaryItemVariants } from '..'
import styles from './ReviewSummary.style'

const ReviewSummaryStyled = styled.div(props => ({ ...styles(props) }))

function ReviewSummary({
    overall,
    totalReviews,
    wouldRecommend,
    wouldBuy,
    buttonTitle,
    buttonHref,
    buttonHrefAs,
    buttonOnClick,
    graphicVariant,
    ...rest
}) {
    if (!overall) {
        return null
    }
    return (
        <ReviewSummaryStyled {...rest}>
            <div className="reviewSummary_content">
                {graphicVariant && <Graphic variant={graphicVariant} className="reviewSummary_graphic" />}
                {overall && (
                    <>
                        <ProductReviewSummaryItem
                            icon={ReviewerBadgeIcon}
                            iconSize={80}
                            value={overall}
                            title="Ocijenile testerice"
                            variant={reviewSummaryItemVariants.BETA}
                            className="reviewSummary_badge"
                        />
                        <div className="reviewSummary_line" />
                    </>
                )}
                {totalReviews && (
                    <ProductReviewSummaryItem
                        icon={ReviewThumbsUpIcon}
                        iconSize={40}
                        value={totalReviews}
                        title="Recenzija testerica"
                        className="reviewSummary_item"
                    />
                )}
                {wouldRecommend && (
                    <ProductReviewSummaryItem
                        icon={ThumbsUpIcon}
                        iconSize={40}
                        value={wouldRecommend}
                        title="Preporučuje proizvod"
                        className="reviewSummary_item"
                    />
                )}
                {wouldBuy && (
                    <ProductReviewSummaryItem
                        icon={ShoppingBagIcon}
                        iconSize={40}
                        value={wouldBuy}
                        title="Bi opet kupile proizvod"
                        titleMaxWidth={120}
                        className="reviewSummary_item"
                    />
                )}
                <Button
                    href={buttonHref}
                    hrefAs={buttonHrefAs}
                    onClick={buttonOnClick}
                    className="reviewSummary_button">
                    {buttonTitle} <Icon icon={ArrowRightIcon} size={32} className="buttonArrowRight" />
                </Button>
            </div>
        </ReviewSummaryStyled>
    )
}

// @TODO revisit props once real data is available
ReviewSummary.propTypes = {
    overall: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalReviews: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    wouldRecommend: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    wouldBuy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    buttonTitle: PropTypes.string,
    buttonHref: PropTypes.string,
    buttonHrefAs: PropTypes.string,
    buttonOnClick: PropTypes.func,
    graphicVariant: PropTypes.oneOf([...Object.values(graphicVariants)])
}

ReviewSummary.defaultProps = {
    overall: undefined,
    totalReviews: undefined,
    wouldRecommend: undefined,
    wouldBuy: undefined,
    buttonTitle: 'Pročitaj sve recenzije',
    buttonHref: undefined,
    buttonHrefAs: undefined,
    buttonOnClick: undefined,
    graphicVariant: graphicVariants.THETA
}
export default withErrorBoundary(ReviewSummary, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ProductReviewSummary]: ', error, componentStack)
    }
})
