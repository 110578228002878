import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Icon } from '../../../Icon'
// @INFO: this is just a temporary placeholder component until the real one is merged
import styles from './ReviewSummaryItem.style'

const ReviewSummaryItemStyled = styled.div(props => ({ ...styles(props) }))

function ReviewSummaryItem({ icon, iconSize, value, title, titleMaxWidth, variant, className, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)
    const valueHtmlProps = useInnerHtml(value)
    const isAmp = useAmp()

    return (
        <ReviewSummaryItemStyled variant={variant} className={className} titleMaxWidth={titleMaxWidth} {...rest}>
            {icon && !isAmp ? (
                <Icon icon={icon} size={iconSize} className="icon" />
            ) : (
                <img src="/images/avatarAmp/avatarAmp.png" alt="avatarAmp" />
            )}
            <div className="content">
                {value && <div className="value" {...valueHtmlProps} />}
                {title && <div className="title" {...titleHtmlProps} />}
            </div>
        </ReviewSummaryItemStyled>
    )
}

const reviewSummaryItemVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

ReviewSummaryItem.propTypes = {
    icon: PropTypes.elementType,
    iconSize: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    titleMaxWidth: PropTypes.number,
    variant: PropTypes.oneOf([...Object.values(reviewSummaryItemVariants)]),
    className: PropTypes.string
}

ReviewSummaryItem.defaultProps = {
    icon: undefined,
    iconSize: 40,
    value: undefined,
    title: undefined,
    titleMaxWidth: 100,
    variant: reviewSummaryItemVariants.ALPHA,
    className: undefined
}
export { reviewSummaryItemVariants }
export default withErrorBoundary(ReviewSummaryItem, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ProductReviewSummaryItem]: ', error, componentStack)
    }
})
