import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& .galleryShare_inner': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: toRem(5),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    '& .galleryShare_text': {
        marginLeft: toRem(8),
        fontWeight: theme.typography.fontWeight.bold,
        color: theme.colors.text.default
    },
    svg: {
        path: {
            fill: theme.colors.text.default
        }
    }
})

export default style
