import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

function Description({ description }) {
    const descriptionHtmlProps = useInnerHtml(description)

    if (!description) {
        return null
    }

    return <div {...descriptionHtmlProps} />
}

Description.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string
}

Description.defaultProps = {
    className: undefined,
    description: undefined
}

export default withErrorBoundary(Description, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RecipeDescription]: ', error, componentStack)
    }
})
