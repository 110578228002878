import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicVariants } from '../../../Decoration/components'
import { HtmlContent } from '../../../HtmlContent'
import styles from './Advice.style'

const AdviceStyled = styled.section(props => ({ ...styles(props) }))

function Advice({ className, title, titleTagComponent: TitleTag, content, ...rest }) {
    if (!content) {
        return null
    }

    return (
        <AdviceStyled className={className} {...rest}>
            <Graphic
                className="recipeAdvice_graphic"
                variant={graphicVariants.GAMMA}
                widthXs={110}
                widthMd={190}
                heightXs={32}
                heightMd={52}
            />
            {title && <TitleTag className="recipeAdvice_title">{title}</TitleTag>}
            <HtmlContent data={content} />
        </AdviceStyled>
    )
}

Advice.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    content: PropTypes.string
}

Advice.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h2',
    content: undefined
}

export default withErrorBoundary(Advice, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RecipeAdvice]: ', error, componentStack)
    }
})
