import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Info.style'

const RecipeInfoStyled = styled.div(props => ({ ...styles(props) }))

function RecipeInfo({ className, label, content, ...rest }) {
    const labelHtmlProps = useInnerHtml(label)
    const contentHtmlProps = useInnerHtml(content)

    if (!label || !content) {
        return null
    }

    return (
        <RecipeInfoStyled className={className} {...rest}>
            <div className="sectionInfo">
                <span className="sectionInfo_title" {...labelHtmlProps} />
                <span className="sectionInfo_content" {...contentHtmlProps} />
            </div>
        </RecipeInfoStyled>
    )
}

RecipeInfo.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.string
}

RecipeInfo.defaultProps = {
    className: undefined,
    label: undefined,
    content: undefined
}

export default RecipeInfo
