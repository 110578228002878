import { toRem, toRems } from '../../../../helpers/theme'

const titleSizes = {
    min: { length: 8, size: 120 },
    max: { length: 24, size: 64 }
}
const getFontSize = length => {
    const { min, max } = titleSizes
    const percent = Math.min(1, Math.max(0, (length - min.length) / (max.length - min.length)))
    const size = min.size + (max.size - min.size) * percent
    return size
}

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, titleLength }) => ({
    paddingTop: toRem(53),
    '& .searchHead': {
        [theme.breakpoints.up('md')]: {
            textAlign: 'center'
        }
    },
    '& .searchHead_label': {
        marginBottom: toRem(12),
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: toRem(20)
        }
    },
    '& .searchHead_title': {
        margin: toRems([10, 0, 16]),
        fontSize: toRem(36),
        [theme.breakpoints.up('md')]: {
            margin: toRems([15, 0]),
            fontSize: toRem(getFontSize(titleLength))
        }
    }
})

export default style
