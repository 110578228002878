/* eslint-disable max-len */
import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: toRem(10),
    [theme.breakpoints.up('md')]: {
        marginTop: toRem(50)
    },
    '& .productContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: toRems([10, 0, 25]),
        [theme.breakpoints.up('md')]: {
            padding: toRems([70, 25, 90, 25])
        },
        '& .item': {
            flex: '1 1 50%'
        }
    }
})

export default style
