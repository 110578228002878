// @TODO: Adjust container dimensions when problem with image size is fixed
import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    // height: '100%',
    background: theme.colors.background.body.secondary,
    '& .galleryItem_wrapper': {
        display: 'flex',
        flexWrap: 'flex-reverse',
        position: 'relative',
        '& .ampImage img': {
            objectFit: 'contain'
        }
    },
    '& .galleryItem_background': {
        opacity: 0.3,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    [theme.breakpoints.up('md')]: {
        '& .galleryItem_imageProgressive': {
            paddingBottom: `clamp(${toRem(320)},  66%, ${toRem(520)})` // ugly hack to contain image in gallery
        }
    },
    '& .galleryItem_image': {
        img: {
            objectFit: 'contain'
        }
    },
    '& .galleryItem_desc': {
        paddingTop: toRem(10),
        paddingBottom: toRem(20),
        borderBottom: `1px solid ${theme.colors.border.darker}`,
        p: {
            margin: 0,
            padding: 0
        }
    },
    '& .galleryItem_title': {
        '& .css-0': {
            marginTop: toRem(15),
            fontSize: toRem(14),
            lineHeight: 1.6,
            color: theme.colors.text.default,
            fontWeight: theme.typography.fontWeight.semiBold
        }
    },
    '& .galleryItem_intro': {
        '& .css-0': {
            fontSize: toRem(14),
            lineHeight: 1.6,
            color: theme.colors.text.default
        }
    }
})

export default style
