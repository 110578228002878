import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { PrevNextButtons, ProductCard, productCardVariants } from '..'
// @INFO: this is just a temporary placeholder component until the real one is merged
import styles from './PrevNext.style'

const PrevNextStyled = styled.div(props => ({ ...styles(props) }))

function PrevNext({ prevItem, nextItem }) {
    return (
        <PrevNextStyled>
            <PrevNextButtons previousHref={prevItem?.sitemap?.href} nextHref={nextItem?.sitemap?.href} />
            <div className="productContainer">
                {prevItem && (
                    <ProductCard
                        item={prevItem}
                        href={prevItem?.sitemap?.href}
                        variant={productCardVariants.BETA}
                        titleTagComponent="h3"
                        imageHeight={400}
                        imageWidth={400}
                        className="item"
                        isPrevNext
                    />
                )}
                {nextItem && (
                    <ProductCard
                        item={nextItem}
                        href={nextItem?.sitemap?.href}
                        variant={productCardVariants.BETA}
                        titleTagComponent="h3"
                        imageHeight={400}
                        imageWidth={400}
                        className="item"
                        isPrevNext
                    />
                )}
            </div>
        </PrevNextStyled>
    )
}

PrevNext.propTypes = {
    prevItem: PropTypes.shape({
        sitemap: PropTypes.shape({
            href: PropTypes.string
        })
    }).isRequired,
    nextItem: PropTypes.shape({
        sitemap: PropTypes.shape({
            href: PropTypes.string
        })
    }).isRequired
}

export default withErrorBoundary(PrevNext, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ProductPrevNext]: ', error, componentStack)
    }
})
