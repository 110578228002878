import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { SocialShare } from '../../../Social/components'
import styles from './Meta.style'

const RecipeMetaStyled = styled.div(props => ({ ...styles(props) }))

function RecipeMeta({ author, date, shareUrl, title, className, ...rest }) {
    const articleDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])
    const isAmp = useAmp()
    return (
        <RecipeMetaStyled className={className} {...rest}>
            {(author || date) && (
                <div className="recipeMeta_inner">
                    {author && <div className="recipeMeta_author">{author}</div>}
                    {date && <div className="recipeMeta_date">{articleDate}</div>}
                </div>
            )}
            <SocialShare
                title={title}
                social={[isAmp ? '' : 'url', 'facebook', 'messenger', 'viber', 'whatsapp']}
                shareUrl={shareUrl}
            />
            <Graphic
                className="recipeMeta_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
        </RecipeMetaStyled>
    )
}

RecipeMeta.propTypes = {
    className: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shareUrl: PropTypes.string,
    title: PropTypes.string
}

RecipeMeta.defaultProps = {
    className: undefined,
    author: undefined,
    date: undefined,
    shareUrl: undefined,
    title: undefined
}

export default RecipeMeta
