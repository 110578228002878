import { useMemo } from 'react'
import { useQuery } from 'react-query'

import useBaseApiURL from '@hmn/rtl-web-core/src/hooks/useBaseApiURL'
import httpClient from '@hmn/rtl-web-core/src/http/client'
import { appQuerySettings } from '@hmn/rtl-web-core/src/queries/utils'

import dataProvider from '@hmn/data-provider'

import useUser from './useUser'

const dateProperties = [
    'published_start',
    'published_at',
    'published_end',
    'archived_at',
    'created_at',
    'updated_at',
    'publish_requested_at',
    'launched_at'
]

const formatProperties = item => {
    if (!item || typeof item !== 'object') {
        return item
    }
    const keys = Object.keys(item)
    if (!keys.length) {
        return item
    }
    return keys.reduce((o, key) => {
        if (dateProperties.includes(key) && typeof item[key] === 'string' && item[key]) {
            o[key] = new Date(item[key])
        } else {
            o[key] = item[key]
        }
        return o
    }, {})
}

export async function submitAnswer(formId, entity, entityId, payload, baseApiURL, token) {
    const response = await httpClient({
        url: `${baseApiURL}/forms/${formId}/answers`,
        method: 'post',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            entity,
            entityId,
            data: payload
        }
    })

    return response.data
}

const requestHeaders = {
    Authorization: dataProvider.getToken() ? `Bearer ${dataProvider.getToken()}` : undefined,
    'Accept-Language': dataProvider.getLanguage()
}

const useForm = ({ id, slug }, options = {}) => {
    const baseApiURL = useBaseApiURL()

    const { enabled = true } = options
    const { profile, isLoggedIn } = useUser()

    const queryKey = useMemo(() => {
        if (id) {
            return [`forms/${id}`, requestHeaders]
        }
        if (slug) {
            return [`forms/slug/${slug}`, requestHeaders]
        }
        return null
    }, [id, slug])

    const [resourceUri, headers] = queryKey || []

    const { data: queryData, isLoading: queryIsLoading } = useQuery(
        queryKey,
        async () => {
            const response = await httpClient({
                url: `${baseApiURL}/${resourceUri}`,
                method: 'get',
                headers
            })

            if (response.status !== 200) {
                return null
            }

            return response.data
        },
        {
            ...appQuerySettings,
            enabled: !!queryKey && !!enabled
        }
    )

    const formData = useMemo(() => formatProperties(queryData), [queryData])

    const formConfig = useMemo(() => {
        const config = formData?.extended_attributes?.form_form_config
        if (!isLoggedIn || !config || !profile?.extended_attributes) {
            return config
        }
        const attrMap = {
            ime: 'first_name',
            prezime: 'last_name',
            grad: 'city',
            adresa: 'address'
        }

        const formFields = config.pages?.[0]?.elements || []

        formFields.forEach(formField => {
            const attributeName = attrMap[formField.name]
            if (attributeName && profile.extended_attributes[attributeName]) {
                formField.defaultValue = profile.extended_attributes[attributeName]
            }
        })
        return config
    }, [formData, profile, isLoggedIn])

    return {
        data: formData,
        type: formData?.extended_attributes?.form_form_type,
        config: formConfig,
        isLoading: queryIsLoading
    }
}

export default useForm
