import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { CardsBlock } from '../../../CardsBlock'
import { EntityCard } from '../../../EntityCard'
import styles from './Related.style'

const RelatedStyled = styled.div(props => ({ ...styles(props) }))

function Related({ className, items, ...rest }) {
    const uid = useUIDSeed()
    if (!items?.length) {
        return null
    }
    return (
        <RelatedStyled className={className} {...rest}>
            <CardsBlock title="Popularni proizvodi">
                {items.map((item, i) => (
                    <EntityCard key={uid(i)} item={item} titleTagComponent="h3" imageHeight={300} imageWidth={300} />
                ))}
            </CardsBlock>
        </RelatedStyled>
    )
}

Related.propTypes = {
    className: PropTypes.string,
    items: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Related.defaultProps = {
    className: undefined,
    items: undefined
}
export default withErrorBoundary(Related, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RelatedProducts]: ', error, componentStack)
    }
})
