import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        xs: {
            button: {
                paddingOuter: toRem(0), // reversed left/right for prev/next buttons
                paddingInner: toRem(10),
                paddingY: toRem(20)
            },
            arrow: {
                size: toRem(32),
                margin: toRem(10)
            }
        },
        md: {
            button: {
                paddingOuter: toRem(25),
                paddingInner: toRem(25),
                paddingY: toRem(20)
            },
            arrow: {
                size: toRem(23),
                margin: toRem(10)
            }
        }
    }
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& .prevNextButtons': {
            '&_graphic': {
                width: '100%',
                display: 'none',
                [theme.breakpoints.up('sm')]: {
                    display: 'block'
                }
            },
            '&_previous, &_next': {
                display: 'flex',
                alignItems: 'center',
                fontSize: toRem(12),
                background: theme.colors.product?.prevNext?.button?.background || theme.colors.devWarning,
                color: theme.colors.product?.prevNext?.button?.color || theme.colors.devWarning,
                letterSpacing: '0.2em',
                fontWeight: theme.typography.fontWeight.bold,
                padding: config.xs.button.padding,
                svg: {
                    width: config.xs.arrow.size,
                    height: config.xs.arrow.size
                }
            },
            '&_previous': {
                paddingTop: config.xs.button.paddingY,
                paddingRight: config.xs.button.paddingInner,
                paddingBottom: config.xs.button.paddingY,
                paddingLeft: config.xs.button.paddingOuter,
                [theme.breakpoints.up('md')]: {
                    paddingTop: config.md.button.paddingY,
                    paddingRight: config.md.button.paddingInner,
                    paddingBottom: config.md.button.paddingY,
                    paddingLeft: config.md.button.paddingOuter
                },
                '& svg': {
                    marginRight: config.xs.arrow.margin,
                    transform: 'rotate(180deg)'
                }
            },
            '&_next': {
                paddingTop: config.xs.button.paddingY,
                paddingRight: config.xs.button.paddingOuter,
                paddingBottom: config.xs.button.paddingY,
                paddingLeft: config.xs.button.paddingInner,
                [theme.breakpoints.up('md')]: {
                    paddingTop: config.md.button.paddingY,
                    paddingRight: config.md.button.paddingOuter,
                    paddingBottom: config.md.button.paddingY,
                    paddingLeft: config.md.button.paddingInner
                },
                '& svg': {
                    marginLeft: config.xs.arrow.margin
                }
            }
        }
    }
}

export default style
