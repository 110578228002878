import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, viewportWidth }) => {
    const fontSize = () => {
        if (viewportWidth < 500 && viewportWidth > 400) {
            return toRem(14)
        }
        if (viewportWidth < 400 && viewportWidth > 330) {
            return toRem(12)
        }
        if (viewportWidth < 329) {
            return toRem(11)
        }
        return toRem(16)
    }
    return {
        display: 'flex',
        alignItems: 'center',
        background: theme.colors.background.body.default,
        '& .galleryMeta_inner': {
            flexShrink: 0,
            marginRight: toRem(10)
        },
        '& .galleryMeta_author': {
            fontSize: fontSize(),
            lineHeight: 1.4,
            fontWeight: theme.typography.fontWeight.bold,
            color: theme.colors.text.default
        },
        '& .galleryMeta_photoAuthor': {
            fontWeight: theme.typography.fontWeight.regular
        },
        '& .galleryMeta_date': {
            color: theme.colors.text.alternative,
            fontSize: fontSize()
        },
        '& .galleryMeta_graphic': {
            width: '100%'
        }
    }
}

export default style
